.Widget {
  width: 20%;
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  transition: all 0.3s;
  transform: scale(0);
  transform-origin: 100% 100%;
  opacity: 0;
  z-index: 999999;
}

.Widget.abrir {
  transform: scale(1);
  opacity: 1;
}

.button-floating {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  height: 3rem;
  width: 3rem;
  z-index: 999999;
}

.pattern-bg {
  background-color: #1d4ed8;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b82f6' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

@media (max-width: 1366px) {
  .Widget {
    width: 30%;
  }
}

@media (max-width: 1024px) {
  .Widget {
    width: 92%;
    bottom: 3rem;
    right: 1rem;
  }

  .Widget.abrir {
    transform: scale(0.89);
    opacity: 1;
  }

  .button-floating {
    bottom: 0.5rem;
    right: 1rem;
    height: 2rem;
    width: 2rem;
  }
}
